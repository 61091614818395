const POST_GRAPHQL_FIELDS = `
    title
    ewerbow
    url
    label
    content
    image
    timeToRead
`

const axios = require('axios')
const isEmpty = require('lodash/isEmpty')

function fetchWithRetry(url, options, retries = 5) {
  return new Promise((resolve, reject) => {
    const fetchPromise = () => axios(url, options)
    const retry = (attempt) => {
      fetchPromise()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(`Contentful API Error - ${error?.response?.status}`)
          if (error?.response && error?.response?.status === 429) {
            console.log(`Retry attempt ${attempt + 1}`)
            setTimeout(() => retry(attempt + 1), 1000) // Wait for 1 second before retrying
          } else if (attempt < retries) {
            console.log(`Retry attempt ${attempt + 1}`)
            setTimeout(() => retry(attempt + 1), 1000) // Wait for 1 second before retrying
          } else {
            reject(error)
          }
        })
    }
    retry(0)
  })
}

async function fetchGraphQL(query, preview = false) {
  const environment = !isEmpty(process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT)
    ? process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT
    : 'master'
  const requestUrl = `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}/environments/${environment}`
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${
        preview
          ? process.env.NEXT_PUBLIC_PREVIEW_TOKEN
          : process.env.NEXT_PUBLIC_CONTENT_DELIVERY_TOKEN
      }`,
    },
    data: { query },
  }

  return fetchWithRetry(requestUrl, requestOptions)
    .then((response) => response.data)
    .catch((error) => console.error(error))
}

module.exports = {
  fetchGraphQL,
}

// function extractPost(fetchResponse) {
//   return fetchResponse?.data?.postCollection?.items?.[0]
// }

// function extractPostEntries(fetchResponse) {
//   return fetchResponse?.data?.postCollection?.items
// }

// export async function getPreviewPostBySlug(slug) {
//   const entry = await fetchGraphQL(
//     `query {
//       postCollection(where: { slug: "${slug}" }, preview: true, limit: 1) {
//         items {
//           ${POST_GRAPHQL_FIELDS}
//         }
//       }
//     }`,
//     true
//   )
//   return extractPost(entry)
// }

// export async function getPostAndMorePosts(slug, preview) {
//   const entry = await fetchGraphQL(
//     `query {
//       postCollection(where: { slug: "${slug}" }, preview: ${
//       preview ? 'true' : 'false'
//     }, limit: 1) {
//         items {
//           ${POST_GRAPHQL_FIELDS}
//         }
//       }
//     }`,
//     preview
//   )
//   const entries = await fetchGraphQL(
//     `query {
//       postCollection(where: { slug_not_in: "${slug}" }, order: date_DESC, preview: ${
//       preview ? 'true' : 'false'
//     }, limit: 2) {
//         items {
//           ${POST_GRAPHQL_FIELDS}
//         }
//       }
//     }`,
//     preview
//   )
//   return {
//     post: extractPost(entry),
//     morePosts: extractPostEntries(entries),
//   }
// }
