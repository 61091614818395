export const disableBodyScroll = () => {
  if (typeof window != 'undefined') {
    const body = document.querySelector('body')

    body.classList.add('overflow-hidden')
  }
}

// enables body scroll by removing overflow-hidden
export const enableBodyScroll2 = () => {
  if (typeof window != 'undefined') {
    const body = document.querySelector('body')

    if (body.classList.contains('overflow-hidden')) {
      body.classList.remove('overflow-hidden')
    }
  }
}

// enables body scroll by removing overflow-hidden
export const enableBodyScroll = (isOpen) => {
  if (typeof window != 'undefined') {
    const body = document.querySelector('body')
    if (isOpen) {
      body.classList.remove('unscroll-body')
    } else {
      if (body.classList.contains('unscroll-body')) {
        body.classList.remove('unscroll-body')
      } else {
        body.classList.add('unscroll-body')
      }
    }
  }
}

export const hiddenWebHeader = (isHidden) => {
  if (typeof window != 'undefined') {
    const webHeader = document.querySelector('.web-header')
    isHidden ? webHeader?.classList?.add('hidden') : webHeader?.classList?.remove('hidden')
  }
}
